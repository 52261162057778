const CONFIG = {
  local: {
    apiUrl: process.env.REACT_APP_API_URL,
  },
  dev: {
    apiUrl: process.env.REACT_APP_API_URL,
  },
  prod: {
    apiUrl: process.env.REACT_APP_API_URL,
  }
};

const environment = process.env.REACT_APP_ENV || 'local';
export const config = CONFIG[environment]; 