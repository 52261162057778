import React from 'react';

import HeroHome from '../components/ui-elements/HeroHome';

function Home() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <main className="flex-grow">
        <HeroHome />
      </main>
    </div>
  );
}

export default Home;