import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from "../ui/button"
import LearnMoreSection from "../LearnMore"
import { CardTitle, CardDescription, CardHeader, CardContent, Card } from "../ui/card"

export default function HeroHome() {
  const features = [
    "Build AI apps in 60 seconds with no code",
    "Call your APIs through conversation",
    "Automate your business processes",
  ];

  const [featureIndex, setFeatureIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setFeatureIndex((current) => (current + 1) % features.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);    

  return (
    <div className="flex flex-col min-h-screen">
      <main className="flex-1">
        <section className="w-full py-4 md:py-8 lg:py-16 xl:py-24">
          <div className="container px-4 md:px-6 mx-auto max-w-7xl">
            <div className="flex flex-col items-center space-y-4 text-center">
              <div className="space-y-2">
                <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl lg:text-6xl/none">
                  10X Your Development Workflow
                </h1>
                <p className="mx-auto max-w-[1000px] text-gray-500 md:text-2xl lg:text-3xl dark:text-gray-400">
                  <span className="gradient-text">Use AI to supercharge productivity by turning your APIs and manual processes into conversations</span>
                  {/* <span className="gradient-text">Use AI to supercharge productivity by turning your API integrations and manual processes into conversations</span> */}
                </p>
              </div>
              <div className="space-x-4">
                <Link to="/login">
                  <Button>Chat now</Button>
                </Link>
                <Link target="_blank" to="https://docs.google.com/forms/d/e/1FAIpQLSc-PDrAc0YvPgw2krZFCIye46g7-Os8arB1yNpl2X-PQgOMEA/viewform?usp=header">
                  <Button variant="outline">Contact us</Button>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section>
          <LearnMoreSection />
        </section>
        <section className="w-full py-16 flex justify-center">
          <Link 
            to="https://docs.google.com/forms/d/e/1FAIpQLSc-PDrAc0YvPgw2krZFCIye46g7-Os8arB1yNpl2X-PQgOMEA/viewform?usp=header"
            target="_blank"
          >
            <button className="px-8 py-4 text-xl font-semibold text-white rounded-lg bg-gradient-to-r from-[#3490dc] to-[#38b2ac] hover:from-[#2779bd] hover:to-[#319795] transform hover:scale-105 transition-all duration-200 shadow-lg">
              Contact us to set up a demo
            </button>
          </Link>
        </section>
        {/* <section className="w-full py-12 md:py-24 lg:py-32 bg-gray-100 dark:bg-gray-800">
          <div className="container px-4 md:px-6 mx-auto max-w-7xl">
            <h2 className="text-3xl font-bold tracking-tighter text-center mb-12">Pricing Plans</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <Card>
                <CardHeader>
                  <CardTitle>Basic</CardTitle>
                  <CardDescription>For individuals just getting started</CardDescription>
                </CardHeader>
                <CardContent>
                  <div className="text-4xl font-bold mb-2">Free</div>
                  <p className="text-sm text-gray-500 dark:text-gray-400">per month</p>
                  <ul className="mt-4 space-y-2">
                    <li className="flex items-center">
                      <CheckIcon className="mr-2 h-4 w-4" />
                      1,000 Chat Credits
                    </li>
                    <li className="flex items-center">
                      <CheckIcon className="mr-2 h-4 w-4" />
                      1 API Collection
                    </li>
                    <li className="flex items-center">
                      <CheckIcon className="mr-2 h-4 w-4" />
                      5 APIs per Collection
                    </li>
                  </ul>
                  <Button className="w-full mt-6">Choose Plan</Button>
                </CardContent>
              </Card>
              <Card>
                <CardHeader>
                  <CardTitle>Pro</CardTitle>
                  <CardDescription>For experienced developers</CardDescription>
                </CardHeader>
                <CardContent>
                  <div className="text-4xl font-bold mb-2">$4.99</div>
                  <p className="text-sm text-gray-500 dark:text-gray-400">month</p>
                  <ul className="mt-4 space-y-2">
                    <li className="flex items-center">
                      <CheckIcon className="mr-2 h-4 w-4" />
                      10,000 Chat Credits
                    </li>
                    <li className="flex items-center">
                      <CheckIcon className="mr-2 h-4 w-4" />
                      Up to 10 Collections
                    </li>
                    <li className="flex items-center">
                      <CheckIcon className="mr-2 h-4 w-4" />
                      10 APIs per Collection
                    </li>
                  </ul>
                  <Button className="w-full mt-6">Choose Plan</Button>
                </CardContent>
              </Card>
              <Card>
                <CardHeader>
                  <CardTitle>Enterprise</CardTitle>
                  <CardDescription>For large-scale operations</CardDescription>
                </CardHeader>
                <CardContent>
                  <div className="text-4xl font-bold mb-2">$9.99</div>
                  <p className="text-sm text-gray-500 dark:text-gray-400">user/month</p>
                  <ul className="mt-4 space-y-2">
                    <li className="flex items-center">
                      <CheckIcon className="mr-2 h-4 w-4" />
                      100,000 Chat Credits per user
                    </li>
                    <li className="flex items-center">
                      <CheckIcon className="mr-2 h-4 w-4" />
                      Unlimited Collections
                    </li>
                    <li className="flex items-center">
                      <CheckIcon className="mr-2 h-4 w-4" />
                      Unlimited APIs per Collection
                    </li>
                  </ul>
                  <Button className="w-full mt-6">Choose Plan</Button>
                </CardContent>
              </Card>
            </div>
          </div>
        </section> */}
      </main>
      <footer className="flex flex-col gap-2 sm:flex-row py-6 w-full shrink-0 items-center px-4 md:px-6 border-t">
        <p className="text-xs text-gray-500 dark:text-gray-400">© 2024 TenXer. All rights reserved.</p>
        <nav className="sm:ml-auto flex gap-4 sm:gap-6">
          <Link className="text-xs hover:underline underline-offset-4" to="/terms">
            Terms of Service
          </Link>
          <Link className="text-xs hover:underline underline-offset-4" to="/privacy">
            Privacy
          </Link>
        </nav>
      </footer>
    </div>
  )
}

function CheckIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polyline points="20 6 9 17 4 12" />
    </svg>
  )
}