import React, { forwardRef, useImperativeHandle, useState } from 'react';

const RequestFieldForm = forwardRef(({ onAdd }, ref) => {
  const [field, setField] = useState({ 
    name: '', 
    type: 'string', 
    description: '', 
    required: false 
  });

  // Expose the current field value to the parent
  useImperativeHandle(ref, () => ({
    getCurrentField: () => field
  }));

  const handleSubmit = () => {
    if (!field.name.trim()) return;
    
    console.log('RequestFieldForm submitting:', field);
    onAdd({...field}); // Send a copy of the field
    
    setField({ 
      name: '', 
      type: 'string', 
      description: '', 
      required: false 
    });
  };

  const handleRequiredChange = (e) => {
    console.log('Required checkbox changed:', e.target.checked);
    setField(prev => ({
      ...prev,
      required: e.target.checked
    }));
  };

  return (
    <div className="space-y-4">
      <div>
        <label htmlFor="fieldName" className="block text-sm font-medium text-gray-700">Field Name</label>
        <input
          type="text"
          id="fieldName"
          className="mt-1 block w-full border border-gray-300 rounded-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          value={field.name}
          onChange={(e) => setField({...field, name: e.target.value})}
          required
        />
      </div>
      <div>
        <label htmlFor="fieldType" className="block text-sm font-medium text-gray-700">Field Type</label>
        <select
          id="fieldType"
          className="mt-1 block w-full border border-gray-300 rounded-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          value={field.type}
          onChange={(e) => setField({...field, type: e.target.value})}
        >
          <option value="string">String</option>
          <option value="integer">Integer</option>
          <option value="boolean">Boolean</option>
          <option value="array">Array</option>
          <option value="object">Object</option>
        </select>
      </div>
      <div>
        <label htmlFor="fieldDescription" className="block text-sm font-medium text-gray-700">Description</label>
        <textarea
          id="fieldDescription"
          className="mt-1 block w-full border border-gray-300 rounded-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          value={field.description}
          onChange={(e) => setField({...field, description: e.target.value})}
        />
      </div>
      <div className="flex items-center">
        <input
          type="checkbox"
          id="fieldRequired"
          className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
          checked={field.required}
          onChange={handleRequiredChange}
        />
        <label htmlFor="fieldRequired" className="ml-2 block text-sm text-gray-900">
          Required
        </label>
      </div>
      <div className="flex justify-end">
        <button 
          type="button"
          onClick={handleSubmit}
          className="btn-primary"
        >
          Add Field
        </button>
      </div>
    </div>
  );
});

export default RequestFieldForm;
