import React, { useState, useEffect, useRef } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useParams } from 'react-router-dom';
import { ChevronRightIcon, ChevronLeftIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { sendMessageToAPI, getApiDefinitions } from '../services/apiClient';
import { Button } from "../components/ui/button"
import { Input } from "../components/ui/input"
import ReactMarkdown from 'react-markdown';

export default function ChatPage() {
    const [messages, setMessages] = useState([]);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [inputMessage, setInputMessage] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [apiDefinitions, setApiDefinitions] = useState(null);
    const [expandedApiCalls, setExpandedApiCalls] = useState({});
    const [apiCalls, setApiCalls] = useState([]);
    const endOfMessagesRef = useRef(null);
    const inputRef = useRef(null);
    const navigate = useNavigate();
    const { collectionId } = useParams();
  
    const { user, isAuthenticated, isLoading } = useAuth0();

    const toggleApiCall = (callIndex) => {
      setExpandedApiCalls(prev => ({
        ...prev,
        [callIndex]: !prev[callIndex]
      }));          
    };
  
    useEffect(() => {
      if (!isLoading && !isAuthenticated) {
        navigate('/');
      }
    }, [isLoading, isAuthenticated, navigate]);
  
    useEffect(() => {
      if (messages.length > 0) {
        endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
      }
    }, [messages]);
  
    useEffect(() => {
      const timer = setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
      
      return () => clearTimeout(timer);
    }, []);
  
    useEffect(() => {
      if (isAuthenticated && user && collectionId) {
        let isWaiting = true;
        const getApiData = async () => {
          try {
            const tenantId = user['https://taskify.com/tenant_id'];
            const apiJson = await getApiDefinitions(tenantId, collectionId);
            if (isWaiting) {
              console.log(apiJson);
              setApiDefinitions(apiJson);
            }
          } catch (error) {
            if (isWaiting) {
              console.error('Error fetching data:', error);
            }
          }
        };
  
        getApiData();
        return () => {
          isWaiting = false;
        };
      }
    }, [isAuthenticated, user, collectionId]);
  
    const handleSendMessage = async () => {
      if (inputMessage.trim()) {
          const newMessage = {
              text: inputMessage,
              author: 'user',
              timestamp: new Date().toLocaleTimeString()
          };
          setMessages(prevMessages => [...prevMessages, newMessage]);
          setInputMessage('');
  
          try {
              setIsProcessing(true);
              const aiResponse = await sendMessageToAPI(inputMessage, apiDefinitions);
              
              if (aiResponse.functionCalls) {
                  setApiCalls(prev => [...prev, ...aiResponse.functionCalls]);
              }
  
              const aiMessage = {
                  text: aiResponse.message,
                  author: 'ai',
                  timestamp: new Date().toLocaleTimeString()
              };
              setMessages(prevMessages => [...prevMessages, aiMessage]);
  
          } catch (error) {
              console.error('Error:', error);
              const errorMessage = {
                  text: "```diff\n- Error: I encountered an error processing your request.\n```",
                  author: 'ai',
                  timestamp: new Date().toLocaleTimeString()
              };
              setMessages(prevMessages => [...prevMessages, errorMessage]);
          } finally {
              setIsProcessing(false);
              endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
          }
      }
    };
  
    if (isLoading) {
      return <div>Loading...</div>;
    }
  
    if (!isAuthenticated) {
      return null;
    }
  
    return (
      <div className="flex justify-center items-start min-h-screen bg-gray-100 pt-2">
        <div className="w-full max-w-6xl bg-white rounded-lg shadow-md overflow-hidden">
          <div className="flex h-[calc(90vh-0.5rem)] relative">
            {/* Main chat area */}
            <div className={`flex flex-col transition-all duration-300 ease-in-out ${
                sidebarOpen ? 'w-[calc(100%-16rem)]' : 'w-full'
            }`}>
              <div className="flex-1 overflow-y-auto p-4 space-y-4">
                {messages.map((msg, index) => (
                  <div key={index} className={`flex ${msg.author === 'user' ? 'justify-end' : 'justify-start'}`}>
                    <div className={`max-w-xs lg:max-w-md p-3 rounded-lg ${
                      msg.author === 'user' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800'
                    }`}>
                      <ReactMarkdown 
                        className={`prose ${msg.author === 'user' ? 'prose-invert' : ''} max-w-none`}
                        components={{
                          p: ({node, ...props}) => <p className="mb-2 last:mb-0" {...props} />,
                          ul: ({node, ...props}) => <ul className="list-disc ml-4 mb-2" {...props} />,
                          ol: ({node, ...props}) => <ol className="list-decimal ml-4 mb-2" {...props} />,
                          code: ({node, inline, ...props}) => (
                            <code className={`${inline ? 'bg-gray-700 text-gray-100 px-1 rounded' : 
                              'block bg-gray-800 text-gray-100 p-2 rounded overflow-x-auto'}`} {...props} />
                          ),
                          pre: ({node, ...props}) => <pre className="bg-transparent p-0" {...props} />,
                          h1: ({node, ...props}) => <h1 className="text-xl font-bold mb-2" {...props} />,
                          h2: ({node, ...props}) => <h2 className="text-lg font-bold mb-2" {...props} />,
                          h3: ({node, ...props}) => <h3 className="text-md font-bold mb-2" {...props} />,
                          a: ({node, ...props}) => (
                            <a className={`${msg.author === 'user' ? 'text-blue-200' : 'text-blue-600'} 
                              hover:underline`} {...props} />
                          ),
                          blockquote: ({node, ...props}) => (
                            <blockquote className="border-l-4 border-gray-300 pl-4 italic" {...props} />
                          ),
                          table: ({node, ...props}) => (
                            <div className="overflow-x-auto">
                              <table className="min-w-full divide-y divide-gray-300" {...props} />
                            </div>
                          ),
                          th: ({node, ...props}) => (
                            <th className="px-3 py-2 bg-gray-100 font-semibold text-left" {...props} />
                          ),
                          td: ({node, ...props}) => (
                            <td className="px-3 py-2 border-t" {...props} />
                          ),
                        }}
                      >
                        {msg.text}
                      </ReactMarkdown>
                      <p className={`text-xs mt-1 ${msg.author === 'user' ? 'text-blue-200' : 'text-gray-500'}`}>
                        {msg.timestamp}
                      </p>
                    </div>
                  </div>
                ))}
                <div ref={endOfMessagesRef} />
              </div>
              <div className="p-2 border-t">
                <div className="flex space-x-3">
                  <Input
                    ref={inputRef}
                    type="text"
                    value={inputMessage}
                    onChange={(e) => setInputMessage(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                    className="flex-1"
                    placeholder="Type your message..."
                  />
                  <Button
                    onClick={handleSendMessage}
                    disabled={isProcessing}
                    className="bg-blue-500 hover:bg-blue-600 text-white"
                  >
                    Send
                  </Button>
                </div>
              </div>
            </div>

            {/* Sidebar */}
            <div className={`absolute right-0 top-0 bottom-0 transition-all duration-300 ease-in-out ${
                sidebarOpen ? 'translate-x-0' : 'translate-x-full'
            }`}>
                {/* Toggle button */}
                <button
                    onClick={() => setSidebarOpen(!sidebarOpen)}
                    className="absolute -left-8 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-l-md shadow-md focus:outline-none"
                >
                    {sidebarOpen ? (
                        <ChevronRightIcon className="h-6 w-6 text-gray-600" />
                    ) : (
                        <ChevronLeftIcon className="h-6 w-6 text-gray-600" />
                    )}
                </button>

                {/* Sidebar content */}
                <div className="w-64 h-full bg-white border-l border-gray-200">
                    <div className="h-full p-4 overflow-y-auto">
                        <h2 className="text-lg font-semibold mb-4">API Calls</h2>
                        {apiCalls.length === 0 ? (
                            <p className="text-sm text-gray-600">No API calls made yet</p>
                        ) : (
                            <div className="space-y-4">
                                {apiCalls.map((call, index) => (
                                    <div key={index} className="bg-gray-50 rounded-lg p-3">
                                        <div 
                                            className="flex items-center justify-between cursor-pointer"
                                            onClick={() => toggleApiCall(index)}
                                        >
                                            <span className="font-medium text-gray-700">
                                                API Call #{index + 1}
                                            </span>
                                            {expandedApiCalls[index] ? (
                                                <ChevronUpIcon className="h-5 w-5 text-gray-500" />
                                            ) : (
                                                <ChevronDownIcon className="h-5 w-5 text-gray-500" />
                                            )}
                                        </div>
                                        
                                        {expandedApiCalls[index] && (
                                            <div className="mt-2 space-y-2">
                                                <div>
                                                    <h4 className="text-sm font-medium text-gray-700">Function:</h4>
                                                    <p className="text-sm text-gray-600">{call.name}</p>
                                                </div>
                                                <div>
                                                    <h4 className="text-sm font-medium text-gray-700">URL:</h4>
                                                    <p className="text-sm text-gray-600 break-all">{call.url}</p>
                                                </div>
                                                {call.authDetails && (
                                                    <div>
                                                        <h4 className="text-sm font-medium text-gray-700">Auth:</h4>
                                                        <pre className="mt-1 p-2 bg-gray-800 text-white rounded text-sm overflow-x-auto">
                                                            {JSON.stringify(call.authDetails, null, 2)}
                                                        </pre>
                                                    </div>
                                                )}
                                                <div>
                                                    <h4 className="text-sm font-medium text-gray-700">Request:</h4>
                                                    <pre className="mt-1 p-2 bg-gray-800 text-white rounded text-sm overflow-x-auto">
                                                        {JSON.stringify(call.request, null, 2)}
                                                    </pre>
                                                </div>
                                                <div>
                                                    <h4 className="text-sm font-medium text-gray-700">Response:</h4>
                                                    <pre className="mt-1 p-2 bg-gray-800 text-white rounded text-sm overflow-x-auto">
                                                        {JSON.stringify(call.response, null, 2)}
                                                    </pre>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    );
}