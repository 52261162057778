import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "../components/ui/button"

export default function Header() {
  const { loginWithRedirect, isAuthenticated, logout } = useAuth0();
  const [top, setTop] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true)
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: { targetUrl: location.pathname },
    });
  };

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  return (
    <header className={`w-full z-30 transition duration-300 ease-in-out ${!top ? 'bg-white dark:bg-gray-800 shadow-lg' : ''}`}>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="flex items-center justify-between h-16 md:h-20">
          <Link className="flex items-center space-x-2" to="/">
            <MountainIcon className="h-6 w-6" />
            <span className="sr-only">TenXer AI</span>
            <span className="text-2xl font-light tracking-tight text-shadow letter-spacing-wide">
              TenXer
            </span>
          </Link>
          <div className="flex items-center space-x-4">
            <Link className="text-sm font-medium hover:underline underline-offset-4" to="/account">
              Account
            </Link>
            {isAuthenticated ? (
              <Link className="text-sm font-medium hover:underline underline-offset-4" onClick={handleLogout}>
                Logout
              </Link>
            ) : (
              <Link className="text-sm font-medium hover:underline underline-offset-4" onClick={handleLogin}>
                Login
              </Link>
            )}
          </div>
        </div>
      </div>
    </header>
  )
}

function MountainIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="url(#mountainGradient)"
      // stroke="black" 
      // strokeWidth="2"
      // strokeLinecap="round"
      // strokeLinejoin="round"
      // className=""
    >
      <path d="m8 3 4 8 5-5 5 15H2L8 3z" />
      <defs>
        <linearGradient id="mountainGradient" x1="0%" y1="100%" x2="100%" y2="0%">
          <stop offset="0%" stopColor="#38b2ac" />
          <stop offset="100%" stopColor="#3490dc" />
        </linearGradient>
      </defs>
    </svg>
  )
}