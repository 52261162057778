export class ApiError extends Error {
  constructor(message, statusCode, details = null) {
    super(message);
    this.name = 'ApiError';
    this.statusCode = statusCode;
    this.details = details;
  }
}

export const getErrorMessage = (error) => {
  if (error instanceof ApiError) {
    if (error.statusCode === 404) {
      return 'The requested resource was not found.';
    }
    if (error.statusCode === 400) {
      return `Invalid request: ${error.details || error.message}`;
    }
    if (error.statusCode === 403) {
      return 'You do not have permission to perform this action.';
    }
    if (error.statusCode === 500) {
      return 'An internal server error occurred. Please try again later.';
    }
    return error.message;
  }
  return 'An unexpected error occurred. Please try again.';
};