import React, { useState, useRef } from 'react';
import { ChevronRightIcon, ChevronLeftIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { Button } from "./ui/button"
import { Input } from "./ui/input"
import ReactMarkdown from 'react-markdown';

export default function DemoChatPage() {
    const [messages, setMessages] = useState([
        { text: "Hi! I'm your TenXer assistant. How can I help you today?", author: 'ai', timestamp: new Date().toLocaleTimeString() }
    ]);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [inputMessage, setInputMessage] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [expandedApiCalls, setExpandedApiCalls] = useState({});
    const [apiCalls, setApiCalls] = useState([]);
    const endOfMessagesRef = useRef(null);
    const inputRef = useRef(null);

    const toggleApiCall = (callIndex) => {
        setExpandedApiCalls(prev => ({
            ...prev,
            [callIndex]: !prev[callIndex]
        }));
    };

    const handleSendMessage = async () => {
        if (inputMessage.trim()) {
            const newMessage = {
                text: inputMessage,
                author: 'user',
                timestamp: new Date().toLocaleTimeString()
            };
            setMessages(prevMessages => [...prevMessages, newMessage]);
            setInputMessage('');
            setIsProcessing(true);

            // Demo response logic
            setTimeout(() => {
                if (inputMessage.toLowerCase().includes('amru@tenxer.ai')) {
                    // First API call - Customer lookup
                    const customerApiCall = {
                        name: "getCustomerProfile",
                        url: "https://api.example.com/customers/lookup",
                        request: { email: "amru@tenxer.ai" },
                        response: {
                            status: "success",
                            data: {
                                id: "CUST-123456",
                                email: "amru@tenxer.ai",
                                name: "Amru Tenxer",
                                subscription: {
                                    plan: "Enterprise",
                                    seats: 50,
                                    activeSeats: 42,
                                    monthlyRate: 999,
                                    startDate: "2023-08-15",
                                },
                                usage: {
                                    apiCalls: 245789,
                                    storageGB: 842,
                                    lastActive: "2024-03-18"
                                }
                            }
                        }
                    };
                    setApiCalls(prev => [...prev, customerApiCall]);

                    const aiResponse = `I found Amru's customer record. Here's what I found:

**Enterprise Subscription Details:**
* 42 active seats out of 50 purchased
* 245,789 API calls made
* 842GB storage usage
* Last active: March 18, 2024

Would you like me to:
1. Calculate current utilization metrics
2. Estimate projected annual costs`;
                    
                    setMessages(prevMessages => [...prevMessages, {
                        text: aiResponse,
                        author: 'ai',
                        timestamp: new Date().toLocaleTimeString()
                    }]);
                } else if (inputMessage.toLowerCase().includes('utilization') || inputMessage.toLowerCase().includes('metrics')) {
                    // Second API call - Utilization calculation
                    const utilizationApiCall = {
                        name: "calculateUtilization",
                        url: "https://api.example.com/analytics/utilization",
                        request: { customerId: "CUST-123456" },
                        response: {
                            status: "success",
                            data: {
                                seatUtilization: 84,
                                storageUtilization: 84.2,
                                apiUtilizationTrend: "+12.5%"
                            }
                        }
                    };
                    setApiCalls(prev => [...prev, utilizationApiCall]);

                    const aiResponse = `Here's the utilization analysis for Amru's account:

**Current Metrics:**
* Seat utilization: **84%** (42/50 seats active)
* Storage utilization: **84.2%** of included storage
* API usage trending: **+12.5%** month-over-month

**Analysis:**
The account shows healthy growth with high resource utilization. The increasing API usage trend suggests active product adoption across the team.

Would you like me to provide recommendations for optimizing their subscription?`;
                    
                    setMessages(prevMessages => [...prevMessages, {
                        text: aiResponse,
                        author: 'ai',
                        timestamp: new Date().toLocaleTimeString()
                    }]);
                } else if (inputMessage.toLowerCase().includes('cost') || inputMessage.toLowerCase().includes('annual')) {
                    // Third API call - Cost projection
                    const costApiCall = {
                        name: "projectAnnualCosts",
                        url: "https://api.example.com/billing/project",
                        request: { customerId: "CUST-123456" },
                        response: {
                            status: "success",
                            data: {
                                currentMonthly: 999,
                                projectedAnnual: 11988,
                                potentialSavings: 1198,
                                recommendedPlan: "Enterprise Annual"
                            }
                        }
                    };
                    setApiCalls(prev => [...prev, costApiCall]);

                    const aiResponse = `There is an opportunity to save 10% on their current subscription:

**Cost Analysis:**
* Current monthly cost: **$999**
* Projected annual cost: **$11,988**
* Potential savings: **$1,198** 

**Recommendations:**
1. Switch to Enterprise Annual plan for 10% discount
2. Pre-pay annually to unlock additional benefits
3. Bundle with support package for better value

Would you like me to show a detailed breakdown of these calculations?`;
                    
                    setMessages(prevMessages => [...prevMessages, {
                        text: aiResponse,
                        author: 'ai',
                        timestamp: new Date().toLocaleTimeString()
                    }]);
                } else {
                    setMessages(prevMessages => [...prevMessages, {
                        text: "I can help you analyze customer data and provide insights. Try asking about a customer using their email, like 'Look up customer amru@tenxer.ai'",
                        author: 'ai',
                        timestamp: new Date().toLocaleTimeString()
                    }]);
                }
                
                setIsProcessing(false);
                endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
            }, 1000);
        }
    };

    return (
        <div className="w-full h-full">
            <div className="flex h-full w-full bg-white rounded-lg overflow-hidden">
                <div className={`flex flex-col transition-all duration-300 ease-in-out ${
                    sidebarOpen ? 'w-[calc(100%-24rem)]' : 'w-full'
                }`}>
                    <div className="flex-1 overflow-y-auto p-4 space-y-4">
                        {messages.map((msg, index) => (
                            <div key={index} className={`flex ${msg.author === 'user' ? 'justify-end' : 'justify-start'}`}>
                                <div className={`max-w-2xl p-3 rounded-lg ${
                                    msg.author === 'user' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800'
                                }`}>
                                    <ReactMarkdown 
                                        className={`prose ${msg.author === 'user' ? 'prose-invert' : ''} max-w-none`}
                                        components={{
                                            // Customize paragraph spacing
                                            p: ({node, ...props}) => <p className="mb-2 last:mb-0" {...props} />,
                                            // Style lists
                                            ul: ({node, ...props}) => <ul className="list-disc ml-4 mb-2" {...props} />,
                                            ol: ({node, ...props}) => <ol className="list-decimal ml-4 mb-2" {...props} />,
                                            // Style code blocks
                                            code: ({node, ...props}) => (
                                                <code className="bg-gray-700 text-gray-100 px-1 rounded" {...props} />
                                            ),
                                        }}
                                    >
                                        {msg.text}
                                    </ReactMarkdown>
                                    <p className={`text-xs mt-1 ${msg.author === 'user' ? 'text-blue-200' : 'text-gray-500'}`}>
                                        {msg.timestamp}
                                    </p>
                                </div>
                            </div>
                        ))}
                        <div ref={endOfMessagesRef} />
                    </div>
                    <div className="p-2 border-t">
                        <div className="flex items-center space-x-3">
                            <Input
                                ref={inputRef}
                                type="text"
                                value={inputMessage}
                                onChange={(e) => setInputMessage(e.target.value)}
                                onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                                className="flex-1"
                                placeholder="Type your message..."
                            />
                            <Button
                                onClick={handleSendMessage}
                                disabled={isProcessing}
                                className="bg-blue-500 hover:bg-blue-600 text-white h-10"
                            >
                                Send
                            </Button>
                        </div>
                    </div>
                </div>

                {/* Sidebar */}
                <div className={`relative transition-all duration-300 ease-in-out ${
                    sidebarOpen ? 'w-96' : 'w-0'
                }`}>
                    {/* Toggle button */}
                    <button
                        onClick={() => setSidebarOpen(!sidebarOpen)}
                        className="absolute -left-8 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-l-md shadow-md focus:outline-none"
                    >
                        {sidebarOpen ? (
                            <ChevronRightIcon className="h-6 w-6 text-gray-600" />
                        ) : (
                            <ChevronLeftIcon className="h-6 w-6 text-gray-600" />
                        )}
                    </button>

                    {/* Sidebar content */}
                    <div className="h-full bg-white border-l border-gray-200">
                        <div className="h-full p-6 overflow-y-auto">
                            <h2 className="text-lg font-semibold mb-4">API Calls</h2>
                            {apiCalls.length === 0 ? (
                                <p className="text-sm text-gray-600">No API calls made yet</p>
                            ) : (
                                <div className="space-y-6">
                                    {apiCalls.map((call, index) => (
                                        <div key={index} className="bg-gray-50 rounded-lg p-4">
                                            <div 
                                                className="flex items-center justify-between cursor-pointer"
                                                onClick={() => toggleApiCall(index)}
                                            >
                                                <span className="font-medium text-gray-700">
                                                    API Call #{index + 1}
                                                </span>
                                                {expandedApiCalls[index] ? (
                                                    <ChevronUpIcon className="h-5 w-5 text-gray-500" />
                                                ) : (
                                                    <ChevronDownIcon className="h-5 w-5 text-gray-500" />
                                                )}
                                            </div>
                                            
                                            {expandedApiCalls[index] && (
                                                <div className="mt-2 space-y-2">
                                                    <div>
                                                        <h4 className="text-sm font-medium text-gray-700">Function:</h4>
                                                        <p className="text-sm text-gray-600">{call.name}</p>
                                                    </div>
                                                    <div>
                                                        <h4 className="text-sm font-medium text-gray-700">URL:</h4>
                                                        <p className="text-sm text-gray-600 break-all">{call.url}</p>
                                                    </div>
                                                    <div>
                                                        <h4 className="text-sm font-medium text-gray-700">Request:</h4>
                                                        <pre className="mt-1 p-2 bg-gray-800 text-white rounded text-sm overflow-x-auto">
                                                            {JSON.stringify(call.request, null, 2)}
                                                        </pre>
                                                    </div>
                                                    <div>
                                                        <h4 className="text-sm font-medium text-gray-700">Response:</h4>
                                                        <pre className="mt-1 p-2 bg-gray-800 text-white rounded text-sm overflow-x-auto">
                                                            {JSON.stringify(call.response, null, 2)}
                                                        </pre>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}