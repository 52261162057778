import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from 'react-router-dom';
import { CheckCircle, XCircle, ChevronDown, ChevronRight, Loader2 } from 'lucide-react';
import { getTenantCollections } from '../services/apiClient';
import { getErrorMessage } from '../utils/errors';
import CollectionForm from '../components/CollectionForm';
import ApiForm from '../components/ApiForm';

import { Button } from "../components/ui/button"
import { Input } from "../components/ui/input"
import { Textarea } from "../components/ui/textarea"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../components/ui/card"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../components/ui/tabs"
import { Alert, AlertDescription, AlertTitle } from "../components/ui/alert"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../components/ui/dialog"
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../components/ui/collapsible"

export default function Account() {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [activeTab, setActiveTab] = useState('profile');
  const [collections, setCollections] = useState([]);
  const [expandedCollection, setExpandedCollection] = useState(null);
  const [showNewCollectionForm, setShowNewCollectionForm] = useState(false);
  const [showNewApiForm, setShowNewApiForm] = useState(false);
  const [error, setError] = useState(null);
  const [isLoadingCollections, setIsLoadingCollections] = useState(false);
  const navigate = useNavigate();

  const tenantId = user?.['https://taskify.com/tenant_id'];

  useEffect(() => {
    const loadCollections = async () => {
      if (!tenantId) return;
      
      setIsLoadingCollections(true);
      setError(null);
      
      try {
        const data = await getTenantCollections(tenantId);
        setCollections(data);
      } catch (err) {
        setError(getErrorMessage(err));
        setCollections([]);
      } finally {
        setIsLoadingCollections(false);
      }
    };

    loadCollections();
  }, [tenantId]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Card className="w-[350px]">
          <CardContent className="flex flex-col items-center justify-center p-6">
            <Loader2 className="h-8 w-8 animate-spin text-blue-500 mb-4" />
            <CardTitle className="text-xl font-semibold mb-2">Loading...</CardTitle>
            <CardDescription>Please wait while we load your account information</CardDescription>
          </CardContent>
        </Card>
      </div>
    );
  }

  if (!isAuthenticated) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Card className="w-[350px]">
          <CardContent className="flex flex-col items-center justify-center p-6">
            <XCircle className="h-8 w-8 text-red-500 mb-4" />
            <CardTitle className="text-xl font-semibold">Not authenticated</CardTitle>
          </CardContent>
        </Card>
      </div>
    );
  }

  const toggleCollection = (id) => {
    setExpandedCollection(expandedCollection === id ? null : id);
  };

  const handleNewCollectionSubmit = async (newCollection) => {
    try {
      const updatedCollections = await getTenantCollections(tenantId);
      setCollections(updatedCollections);
      setShowNewCollectionForm(false);
    } catch (err) {
      setError(getErrorMessage(err));
    }
  };

  const handleNewApiSubmit = async (newApi) => {
    try {
      const updatedCollections = await getTenantCollections(tenantId);
      setCollections(updatedCollections);
      setShowNewApiForm(false);
    } catch (err) {
      setError(getErrorMessage(err));
    }
  };

  const handleLaunchChat = (collectionId) => {
    navigate(`/chat/${collectionId}`);
  };

  return (
    <div className="flex flex-col min-h-screen">
      <main className="flex-1">
        <section className="w-full py-4 md:py-8 lg:py-16 xl:py-24">
          <div className="container px-8 md:px-10 mx-auto max-w-7xl">
            <div className="flex flex-col space-y-4">
      <h1 className="text-4xl font-bold mb-8">Your Account</h1>
      
      <Tabs defaultValue="profile" className="w-full">
        <TabsList className="bg-transparent">
          <TabsTrigger value="profile">Profile</TabsTrigger>
          <TabsTrigger value="collections">Collections</TabsTrigger>
        </TabsList>
        <TabsContent value="profile">
          <Card>
            <CardHeader>
              <CardTitle>Profile Information</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-2">
                <p><strong>Email:</strong> {user.email}</p>
                <p><strong>Name:</strong> {user.name}</p>
                <p><strong>Member Since:</strong> January 1, 2023</p>
                <p><strong>Tenant Id:</strong> {tenantId}</p>
              </div>
            </CardContent>
          </Card>
        </TabsContent>
        <TabsContent value="collections">
          <Card>
            <CardHeader>
              <CardTitle>Your Collections</CardTitle>
              <CardDescription>Manage your collections and APIs</CardDescription>
            </CardHeader>
            <CardContent>
              {error && (
                <Alert variant="destructive" className="mb-4">
                  <AlertTitle>Error</AlertTitle>
                  <AlertDescription>{error}</AlertDescription>
                </Alert>
              )}

              <div className="flex space-x-4 mb-4">
                <Dialog open={showNewCollectionForm} onOpenChange={setShowNewCollectionForm}>
                  <DialogTrigger asChild>
                    <Button>Create New Collection</Button>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle>Create New Collection</DialogTitle>
                      <DialogDescription>Fill in the details to create a new collection.</DialogDescription>
                    </DialogHeader>
                    <CollectionForm
                      tenantId={tenantId}
                      onSubmit={handleNewCollectionSubmit}
                      onCancel={() => setShowNewCollectionForm(false)}
                    />
                  </DialogContent>
                </Dialog>

                <Dialog open={showNewApiForm} onOpenChange={setShowNewApiForm} >
                  <DialogTrigger asChild>
                    <Button variant="outline">Create New API</Button>
                  </DialogTrigger>
                  <DialogContent className="max-w-3xl max-h-[90vh] overflow-y-auto left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]">
                    <DialogHeader>
                      <DialogTitle>Create New API</DialogTitle>
                      <DialogDescription>Fill in the details to create a new API.</DialogDescription>
                    </DialogHeader>
                    <ApiForm
                      tenantId={tenantId}
                      collections={collections}
                      onSubmit={handleNewApiSubmit}
                      onCancel={() => setShowNewApiForm(false)}
                    />
                  </DialogContent>
                </Dialog>
              </div>

              {isLoadingCollections ? (
                <div className="text-center py-4">
                  <Loader2 className="h-8 w-8 animate-spin mx-auto" />
                  <p className="mt-2">Loading collections...</p>
                </div>
              ) : collections.length === 0 ? (
                <p className="text-center py-4 text-gray-500">
                  No collections found. Create your first collection to get started!
                </p>
              ) : (
                <div className="space-y-4">
                  {collections.map((collection) => (
                    <Collapsible key={collection.collection_id}>
                      <div className="flex items-center justify-between">
                        <CollapsibleTrigger asChild>
                          <Button variant="ghost" className="w-[calc(100%-120px)] justify-between">
                            <span>{collection.collection_name}</span>
                            <ChevronDown className="h-4 w-4" />
                          </Button>
                        </CollapsibleTrigger>
                        <Button
                          size="sm"
                          onClick={() => handleLaunchChat(collection.collection_id)}
                        >
                          Launch Chat
                        </Button>
                      </div>
                      <CollapsibleContent className="mt-2 p-4 bg-muted rounded-md">
                        <p><strong>Description:</strong> {collection.collection_description}</p>
                        <h3 className="font-semibold mt-2">APIs:</h3>
                        <pre className="mt-2 p-4 bg-muted-foreground/10 rounded-md overflow-x-auto text-sm">
                          {JSON.stringify(collection.apis, null, 2)}
                        </pre>
                      </CollapsibleContent>
                    </Collapsible>
                  ))}
                </div>
              )}
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
