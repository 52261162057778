import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';

const StatusPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);

  const error = query.get('error');
  const message = query.get('error_description') || 'Login successful. Redirecting...';

  useEffect(() => {
    if (!error) {
      // Redirect to /account page after 3 seconds if there's no error
      const timer = setTimeout(() => {
        navigate('/account');
      }, 3000);

      // Clean up the timer
      return () => clearTimeout(timer);
    }
  }, [error, navigate]);

  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="text-4xl md:text-5xl font-extrabold leading-tighter tracking-tighter mb-4" data-aos="zoom-y-out">
              Status
            </h1>
            <div className="max-w-xl mx-auto">
              <div className="mt-8 bg-white shadow-lg rounded-sm p-6">
                {error ? (
                  <div className="flex items-center justify-center text-red-600">
                    <XCircleIcon className="w-8 h-8 mr-2" />
                    <p className="text-xl font-semibold">Error: {message}</p>
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center text-green-600">
                    <div className="flex items-center mb-4">
                      <CheckCircleIcon className="w-8 h-8 mr-2" />
                      <p className="text-xl font-semibold">{message}</p>
                    </div>
                    <p className="text-sm text-gray-500">Redirecting to your account page in 3 seconds...</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StatusPage;
