import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const SignIn = () => {
  const location = useLocation();
  const { logout, loginWithRedirect, error } = useAuth0();

  useEffect(() => {
    console.log(location.state);
    if (location.state && location.state.action === 'logout') {
      logout({ returnTo: window.location.origin });
    } else if (location.state && location.state.action === 'login') {
      loginWithRedirect();
    }
  }, [location.state, logout, loginWithRedirect]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }
}
export default SignIn;