import React, { useState } from 'react';
import yaml from 'js-yaml';

export default function JsonYamlInput({ onValidInput }) {
  const [input, setInput] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const validateInput = (value) => {
    try {
      // Try parsing as JSON
      JSON.parse(value);
      setIsValid(true);
      setErrorMessage('');
      return true;
    } catch (jsonError) {
      try {
        // If JSON parsing fails, try parsing as YAML
        yaml.load(value);
        setIsValid(true);
        setErrorMessage('');
        return true;
      } catch (yamlError) {
        setIsValid(false);
        setErrorMessage('Invalid JSON or YAML format');
        return false;
      }
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInput(value);
    if (value.trim() === '') {
      setIsValid(true);
      setErrorMessage('');
    } else {
      validateInput(value);
    }
  };

  const handleSubmit = () => {
    if (validateInput(input)) {
      try {
        const parsedData = JSON.parse(input);
        onValidInput(parsedData);
      } catch (jsonError) {
        const parsedData = yaml.load(input);
        onValidInput(parsedData);
      }
    }
  };

  return (
    <div className="space-y-4">
      <textarea
        className={`w-full h-64 p-2 border ${isValid ? 'border-gray-300' : 'border-red-500'} rounded-sm`}
        value={input}
        onChange={handleInputChange}
        placeholder="Paste your JSON or YAML here"
      />
      {!isValid && <p className="text-red-500 text-sm">{errorMessage}</p>}
      <button
        className="btn-primary w-full"
        onClick={handleSubmit}
        disabled={!isValid || input.trim() === ''}
      >
        Submit
      </button>
    </div>
  );
}
