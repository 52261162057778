import React, { useState, useEffect, useRef } from 'react';
import { createApi } from '../services/apiClient';
import { getErrorMessage } from '../utils/errors';
import JsonYamlInput from './JsonYamlInput';
import RequestFieldForm from './RequestFieldForm';
import ResponseFieldForm from './ResponseFieldForm';
import { sanitizeApiName } from '../utils/validation';

export default function ApiForm({ tenantId, collections, onSubmit, onCancel }) {
  const [api, setApi] = useState({
    collectionId: '',
    name: '',
    description: '',
    endpoint: '',
    method: 'POST',
    authHeaders: {},
    requestFields: [],
    responseFields: []
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [useJsonYaml, setUseJsonYaml] = useState(false);

  const methodsWithRequestBody = ['POST', 'PUT', 'PATCH'];
  const showRequestFields = methodsWithRequestBody.includes(api.method);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    if (name === 'name') {
      const sanitizedName = sanitizeApiName(value);
      if (sanitizedName !== value) {
        setError('API name can only contain letters, numbers, underscores, and hyphens');
      } else {
        setError(null);
      }
      setApi(prev => ({
        ...prev,
        [name]: sanitizedName
      }));
    } else {
      setApi(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    
    if (!api.name.match(/^[a-zA-Z0-9_-]+$/)) {
      setError('API name can only contain letters, numbers, underscores, and hyphens');
      return;
    }

    setIsLoading(true);

    const currentRequestField = requestFormRef.current?.getCurrentField();
    const currentResponseField = responseFormRef.current?.getCurrentField();

    console.log('Submitting API with state:', api);

    const apiData = {
      ...api,
      request: {
        fields: [
          ...api.requestFields,
          ...(currentRequestField?.name ? [currentRequestField] : [])
        ]
      },
      response: {
        fields: [
          ...api.responseFields,
          ...(currentResponseField?.name ? [currentResponseField] : [])
        ]
      }
    };

    console.log('Final API payload:', apiData);

    try {
      const result = await createApi(tenantId, api.collectionId, apiData);
      onSubmit(result);
    } catch (error) {
      setError(getErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddRequestField = (field) => {
    console.log('Adding request field:', field);
    setApi(prev => {
      const newApi = {
        ...prev,
        requestFields: [...(prev.requestFields || []), field]
      };
      console.log('Updated API with new request fields:', newApi);
      return newApi;
    });
  };

  const handleAddResponseField = (field) => {
    console.log('Adding response field:', field);
    setApi(prev => {
      const newApi = {
        ...prev,
        responseFields: [...(prev.responseFields || []), field]
      };
      console.log('Updated API with new response fields:', newApi);
      return newApi;
    });
  };

  useEffect(() => {
    console.log('Current API state:', {
      ...api,
      requestFields: api.requestFields || [],
      responseFields: api.responseFields || []
    });
  }, [api]);

  const requestFormRef = useRef();
  const responseFormRef = useRef();

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      {error && (
        <div className="bg-red-50 border-l-4 border-red-400 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-red-700">{error}</p>
            </div>
          </div>
        </div>
      )}

      <div>
        <label htmlFor="collectionId" className="block text-sm font-medium text-gray-700">
          Collection
        </label>
        <select
          id="collectionId"
          name="collectionId"
          value={api.collectionId}
          onChange={handleInputChange}
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          required
        >
          <option value="">Select a collection</option>
          {collections.map((collection) => (
            <option 
              key={collection.collection_id} 
              value={collection.collection_id}
            >
              {collection.collection_name}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Input Format
        </label>
        <div className="flex items-center space-x-4 mb-4">
          <label className="inline-flex items-center">
            <input
              type="radio"
              className="form-radio"
              checked={!useJsonYaml}
              onChange={() => setUseJsonYaml(false)}
            />
            <span className="ml-2">Form</span>
          </label>
          <label className="inline-flex items-center">
            <input
              type="radio"
              className="form-radio"
              checked={useJsonYaml}
              onChange={() => setUseJsonYaml(true)}
            />
            <span className="ml-2">JSON/YAML</span>
          </label>
        </div>
      </div>

      {useJsonYaml ? (
        <JsonYamlInput
          value={api}
          onChange={(newValue) => setApi(newValue)}
        />
      ) : (
        <>
          <div className="space-y-4">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                API Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={api.name}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                required
              />
            </div>

            <div>
              <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                Description
              </label>
              <textarea
                id="description"
                name="description"
                value={api.description}
                onChange={handleInputChange}
                rows={3}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label htmlFor="endpoint" className="block text-sm font-medium text-gray-700">
                  Endpoint URL
                </label>
                <input
                  type="url"
                  id="endpoint"
                  name="endpoint"
                  value={api.endpoint}
                  onChange={handleInputChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                />
              </div>

              <div>
                <label htmlFor="method" className="block text-sm font-medium text-gray-700">
                  HTTP Method
                </label>
                <select
                  id="method"
                  name="method"
                  value={api.method}
                  onChange={handleInputChange}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                >
                  <option value="GET">GET</option>
                  <option value="POST">POST</option>
                  <option value="PUT">PUT</option>
                  <option value="DELETE">DELETE</option>
                  <option value="PATCH">PATCH</option>
                </select>
              </div>
            </div>

            <div>
              <label htmlFor="authHeaders" className="block text-sm font-medium text-gray-700">
                Auth Headers (JSON)
              </label>
              <textarea
                id="authHeaders"
                name="authHeaders"
                value={JSON.stringify(api.authHeaders, null, 2)}
                onChange={(e) => {
                  try {
                    const parsed = JSON.parse(e.target.value);
                    setApi(prev => ({
                      ...prev,
                      authHeaders: parsed
                    }));
                  } catch (error) {
                    // Handle invalid JSON input
                  }
                }}
                rows={3}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm font-mono"
              />
            </div>
          </div>

          {showRequestFields && (
            <div className="border-t border-gray-200 pt-4">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Request Fields</h3>
              <div className="bg-gray-50 p-4 rounded-sm mb-4">
                <RequestFieldForm 
                  ref={requestFormRef}
                  onAdd={handleAddRequestField} 
                />
              </div>
              {api.requestFields.length > 0 && (
                <div className="mt-4">
                  <h4 className="text-sm font-medium text-gray-700 mb-2">Added Request Fields:</h4>
                  <ul className="space-y-2">
                    {api.requestFields.map((field, index) => (
                      <li key={index} className="bg-white p-3 rounded-sm border">
                        <div className="flex justify-between items-start">
                          <div>
                            <p className="font-medium">{field.name}</p>
                            <p className="text-sm text-gray-500">Type: {field.type}</p>
                            {field.description && (
                              <p className="text-sm text-gray-500">Description: {field.description}</p>
                            )}
                            {field.required && (
                              <p className="text-sm text-red-500">Required</p>
                            )}
                          </div>
                          <button
                            type="button"
                            className="text-red-600 hover:text-red-800"
                            onClick={() => {
                              setApi(prev => ({
                                ...prev,
                                requestFields: prev.requestFields.filter((_, i) => i !== index)
                              }));
                            }}
                          >
                            Remove
                          </button>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          )}

          <div className="border-t border-gray-200 pt-4">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Response Fields</h3>
            <div className="bg-gray-50 p-4 rounded-sm mb-4">
              <ResponseFieldForm 
                ref={responseFormRef}
                onAdd={handleAddResponseField} 
              />
            </div>
            {api.responseFields && api.responseFields.length > 0 && (
              <div className="mt-4">
                <h4 className="text-sm font-medium text-gray-700 mb-2">Added Response Fields:</h4>
                <ul className="space-y-2">
                  {api.responseFields.map((field, index) => (
                    <li key={index} className="bg-white p-3 rounded-sm border">
                      <div className="flex justify-between items-start">
                        <div>
                          <p className="font-medium">{field.name}</p>
                          <p className="text-sm text-gray-500">Type: {field.type}</p>
                          {field.description && (
                            <p className="text-sm text-gray-500">Description: {field.description}</p>
                          )}
                        </div>
                        <button
                          type="button"
                          className="text-red-600 hover:text-red-800"
                          onClick={() => {
                            setApi(prev => ({
                              ...prev,
                              responseFields: prev.responseFields.filter((_, i) => i !== index)
                            }));
                          }}
                        >
                          Remove
                        </button>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </>
      )}

      <div className="flex justify-end space-x-2">
        <button 
          type="button" 
          className="btn-secondary" 
          onClick={onCancel}
          disabled={isLoading}
        >
          Cancel
        </button>
        <button 
          type="submit"
          className={`btn-primary ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {isLoading ? 'Creating API...' : 'Create API'}
        </button>
      </div>
    </form>
  );
}
