import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './assets/css/style.css';
import './assets/css/App.css';

import { Auth0Provider } from '@auth0/auth0-react';
import Header from './components/Header';
import Home from './views/Home';
import SignIn from './views/SignIn';
import SignUp from './views/SignUp';
import StatusPage from './views/StatusPage';
import Account from './views/Account';
import ResetPassword from './views/ResetPassword';
import ChatPage from './views/ChatPage';
import DemoChatPage from './components/DemoChatPage';
import { sendMessageToAPI, getApiDefinitions } from './services/apiClient';

function App() {
  const [apiDefinitions, setApiDefinitions] = useState([]);
  const [messages, setMessages] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  console.log(process.env.REACT_APP_API_URL);

  const onRedirectCallback = (appState) => {
    const searchParams = new URLSearchParams(window.location.search);
    const error = searchParams.get('error');
    const errorDescription = searchParams.get('error_description');
  
    if (error) {
      navigate(`/status?error=${error}&message=${encodeURIComponent(errorDescription)}`);
    } else {
      const targetUrl = appState?.targetUrl || '/';
      navigate(targetUrl);
    }
  };

  // // Initialize user-defined API tools
  // useEffect(() => {

  //   // Get API definitions
  //   let isWaiting = true;
  //   const getApiData = async () => {
  //       try {
  //           // Get user's tenant from the login token and pass it in below
  //           const tenantId = "750cb5f01daa4467a18120f3bcf440b9"
  //           const apiJson = await getApiDefinitions(tenantId);
  //           if (isWaiting) {
  //               console.log(apiJson);
  //               setApiDefinitions(apiJson);
  //           }
  //       } catch (error) {
  //           if (isWaiting) {
  //               console.error('Error fetching data:', error);
  //           }
  //       }
  //   };

  //   getApiData();
  //   return () => {
  //       isWaiting = false;
  //   };
  // }, []);

  // AOS initialization
  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 700,
      easing: 'ease-out-cubic',
    });
  }, []);

  // Scroll to top on route change
  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto';
    window.scroll({ top: 0 });
    document.querySelector('html').style.scrollBehavior = '';
  }, [location.pathname]);

  // const handleSendMessage = async (newMessage) => {
  //   // Update messages with user's message
  //   setMessages([...messages, { text: newMessage, author: 'user', timestamp: new Date().toLocaleTimeString() }]);

  //   // Send message to API and get AI response
  //   const aiResponse = await sendMessageToAPI(newMessage, apiDefinitions);
  //   setMessages(prev => [...prev, { text: aiResponse, author: 'ai', timestamp: new Date().toLocaleTimeString() }]);
  // };

  return (
    <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    authorizationParams={{
      redirect_uri: `${window.location.origin}/status`,
      scope: 'openid profile email',
    }}
    cacheLocation="localstorage"  // Ensure the user stays logged in
    useRefreshTokens={true}  // Use refresh tokens to extend session
    onRedirectCallback={onRedirectCallback}
  >
    <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/status" element={<StatusPage />} />
        <Route path="/account" element={<Account />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/chat" element={<ChatPage />} />
        <Route path="/chat/:collectionId" element={<ChatPage />} />
        <Route path="/demo" element={<DemoChatPage />} />
      </Routes>
      </Auth0Provider>
  );
}

export default App;
